import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'trailer',
  data () {
    return {
      optionsSmall: {
        muted: 1,
        autoplay: 1,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
      },
      videoHeight: 370
    }
  },
  computed: {
    ...mapGetters({
      videoPopupId: 'packages/videoPopup'
    })
  },
  methods: {
    ...mapMutations({
      changeVideoPopupInfo: 'packages/CHANGE_VIDEO_POPUP_INFO'
    })
  },
  destroyed () {
    this.changeVideoPopupInfo({ videoId: null })
  }
}
